<script>
import TemperatureTable from "@/components/CMS/Sub-component/Temperature/TemperatureTable.vue"
import TemperatureSaveDialog from "@/components/CMS/Sub-component/Temperature/TemperatureSaveDialog.vue"

export default {
  name: "EnvEdit",
  components: { TemperatureSaveDialog, TemperatureTable },
  props: {
    agentId: [Number, String],
    channels: Array,
    isActive: Boolean,
  },
  data() {
    return {
      isLoading: false,
      dialogIsVisible: false,
      temperatures: [],
      temperatureNames: [],
      selectedTemperature: null,
    }
  },
  watch: {
    isActive(newValue) {
      if (newValue) this.getTemperatures()
    },
  },
  methods: {
    async getTemperatures() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/agents/${this.agentId}/temperature-configs`)
        this.temperatures = data.data || []
        this.temperatureNames = data?.temperature_names || []
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    async deleteTemperature(id) {
      try {
        await this.$axios.delete(`admin/temperature-configs/${id}`)
        await this.getTemperatures()
      } catch (e) {
        throw new Error(e)
      }
    },
    onRowClick(event) {
      this.selectedTemperature = event
      this.dialogIsVisible = true
    },
    // async getEnvs() {
    //   try {
    //     const { data } = await this.$axios.get(`agents/${this.agentId}/message-dependent?type=env`)
    //     console.log(data)
    //   } catch (e) {
    //     console.error(e)
    //     throw e
    //   }
    // }
  },
}
</script>

<template>
  <div class="has-background-white p-5">
    <TemperatureSaveDialog
      :is-visible.sync="dialogIsVisible"
      :temperature.sync="selectedTemperature"
      :names="temperatureNames"
      :agent-id="agentId"
      @refresh="getTemperatures"
    />
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-5">
      <h2 class="is-size-5 has-text-weight-semibold">Температуры</h2>
      <b-button type="is-info" @click="dialogIsVisible = true">Добавить</b-button>
    </div>
    <TemperatureTable :data="temperatures" :loading="isLoading" @rowClick="onRowClick" @delete="deleteTemperature" />
  </div>
</template>

<style scoped>

</style>
